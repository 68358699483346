<template>
  <!-- 轮播 -->
  <div id="banner" class="banner-box">
    <img style="width:100%;height:100%;objectFit:cover;" src="@/assets/image/banner1.jpg" alt="">
    <!-- <div class="form_line">
      <el-form ref="form" class="el_form">
        <el-input
          id="el-input__inner"
          v-model="form.content"
          placeholder="请输入内容"
          style="width:578px;"
          clearable
        ></el-input>
        <el-select
            v-model="form.price"
            placeholder="价格"
            style="width:105px;"
          >
            <el-option
              v-for="item in pricelist"
              :key="item.price"
              :label="item.price"
              :value="item.price"
            >
            </el-option>
          </el-select>
        <el-select
          id="el-select"
          v-model="form.zb"
          placeholder="分类"
          style="width:105px;"
          clearable=""
        >
          <el-option
            v-for="item in classl"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="mybtn" @click="savecat_id">
          搜索
        </div>
      </el-form>
    </div> -->
  </div>
</template>
<script>
import { classlist } from '@/api/indexapi'
export default {
  name: 'Banner',
  data() {
    return {
      form: {
        content: '',
        // price: "",
        zb: ''
      },
      classl: ''
    }
  },
  mounted() {
    this.classlistM()
  },
  methods: {
    classlistM() {
      classlist()
        .then((res) => {
          this.classl = res.result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //  存储分类id,搜索内容
    savecat_id() {
      this.$router.push({
        path: '/gift/searchResult',
        query: { category_id: this.form.zb, content: this.form.content }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mybtn{
  width: 135px;
  cursor: pointer;
  height: 45px;
  background: #ee2e2e;
  display: inline-block;
  // float: right;
  color: #fff;
}
.banner-box {
  // border: 1px solid red;
  height: 550px;
  // background: pink;
  position: relative;
  .form_line {
    position: absolute;
    left: 50%;
    margin-left: -499px;
    bottom: 10px;
    padding: 0 90px;
    z-index: 2;
    .el_form {
      width: 100%;
      height: 45px;
      line-height: 45px;
      background: white;
    }
  }
}
/deep/#el-input__inner {
  border: 0px;
  border-right: 1px solid #ccc;
  border-radius: 0px;
  height: 29px;
  line-height: 29px;
  margin: 8px 0px;
}
/deep/ .el-input__inner{
  border: none;
}
/deep/.el-button--primary {
  height: 100%;
  border-radius: 0px;
  border: 0px;
  float: right;
}
.safe-box {
  width: 1200px;
  margin: 0 auto;
}
</style>
