<template>
  <div class="main">
    <banner></banner>
    <div class="main-box">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import banner from '@/components/indexcom/banner'
export default {
  components: {
    banner
  },
  data() {
    return {
      zlyc: [],
      jdyx: []
    }
  },
  mounted() {
    document.title = '礼品兑换'
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
// 主题区域
.main{
  background-color: #e5e5e5;
}
.main-box {
  width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  background-color: #fff;
  margin-top: 0px;
  position: relative;
}
.category{
  height: 80px;
  background-color: white;
  border-bottom: 1px solid #ccc;
}
</style>
